import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import axios from 'axios'
import VueAxios from 'vue-axios'
import * as echarts from 'echarts';

import "./assets/css/base.css"
Vue.config.productionTip = false

Vue.use(ElementUI);
Vue.use(VueAxios, axios);

import request from "./utils/request.js"
import formatTime from "./utils/formatTime.js"

Vue.prototype.$request = request
Vue.prototype.$formatTime = formatTime
Vue.prototype.$echarts = echarts

import * as baseData from "./assets/js/base.js"
Vue.prototype.$baseData = baseData

// Vue.prototype.$baseurl = 'http://192.168.0.109:8090'
Vue.prototype.$baseurl = 'https://hfwb.ah-cz.cn/api'


Vue.prototype.$cardTypeList = [
    { label: "全部", value: "all" },
    { label: "合肥日报", value: "0" },
    { label: "江淮晨报", value: "1" },
    { label: "合肥晚报", value: "2" },
]


import search_list from "./components/search/"
Vue.use(search_list)


// 添加请求拦截器，在请求头中加token
axios.interceptors.request.use(
    config => {
        if (window.sessionStorage.getItem('token')) {
            config.headers.token = window.sessionStorage.getItem('token')
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    });

new Vue({
    router,
    render: h => h(App)
}).$mount('#app')